.navBar {
  position: sticky;
  z-index: 30;
  top: 0;
  width: 100%;
  background-color: var(--colorTan1);
}

.isScrolled {
  border-bottom: 1px solid var(--colorTan3);
}

.fieldfolioLogo {
  padding: 0 !important;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  color: inherit;
  margin: auto;
}

.logoAndSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}

.searchWrapper {
  padding-right: 2rem;
  padding-left: 2rem;
  flex-grow: 1;
  display: none; /* Hide on mobile */
}

@media (--viewportTabletMin) {
  .searchWrapper {
    display: initial;
  }
}

.navList {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0rem;
  list-style: none;
}

.navList.isElastic {
  flex-grow: 1;
}

.logotype {
  margin-left: 0.5rem;
  font-weight: bold;
}

.accountNavLabel {
  max-width: 12rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.repNavNameContainer {
  cursor: default;
}

@media (--viewportPhoneMax) {
  .dropdown {
    top: 3.5rem !important;
  }
}

.sellButton,
.loginButton,
.signupButton {
  white-space: nowrap;
}

.loginButton,
.sellButton {
  margin-right: 1rem;
  display: none;
}

@media (--viewportTabletMin) {
  .loginButton,
  .sellButton {
    display: flex;
  }
}

@media (--viewportTabletLandscapeMin) {
  .signupButton {
    display: flex !important;
  }
}
