.categoriesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--colorGray2);
  margin-bottom: 1.25rem;
  width: 100%;
}

.categoryName {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray11);
  text-transform: capitalize;
  cursor: pointer;
}
.selectedCategoryName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorGray11);
  text-transform: capitalize;
  margin-bottom: 0;
  cursor: pointer;
}

.allCategoriesLink {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.allCategoriesLink span {
  margin-left: 0.25rem;
}

.subCategoriesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.navigationHeading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 0.25rem;
}
