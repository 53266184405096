.departmentsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.allDepartmentsLink {
  display: flex;
  align-items: center;
}
.allDepartmentsLink span {
  margin-left: 0.25rem;
}
.departmentName {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray11);
  text-transform: capitalize;
}
.selectedDepartmentName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorGray11);
  text-transform: capitalize;
  margin-bottom: 0;
}
.selectedDepartmentName a {
  color: inherit;
}
.disablePointerEvents {
  pointer-events: none;
}
.subDepartmentsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-left: 1rem;
}
.navigationHeading {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 0.25rem;
}
.navigationHeading a {
  color: inherit;
}
