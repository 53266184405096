.footer {
  width: 100%;
  background-color: var(--colorTan1);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer a:hover {
  color: var(--colorAnchorTextHover);
}

.logo {
  width: 115px;
  height: 32px;
}

.logoContainer {
  padding-bottom: 2rem;
}

.linklist {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--sh_colorGray7);
  composes: ui14Regular from 'styles/typeography.css';
}

.listContainer > ul > li {
  margin-top: 0.25rem;
}
