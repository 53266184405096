/* Row containers */

.base {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (--viewportTabletMin) {
  .base {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.slim {
  composes: base;
  max-width: 42rem;
}

.regular {
  composes: base;
  max-width: calc(((var(--catalogCoverWidth) + 2rem) * 4) + 4rem);
}

.wide {
  composes: base;
  max-width: 84rem;
}

/* Generic Container */

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (--viewportTabletMin) {
  .container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media (min-width: 80rem) {
  .container {
    padding-right: 0;
    padding-left: 0;
    width: 74rem; /* 1184px */
  }
}
