.accountList {
  padding: 0 1rem 1rem 1rem;
}

.accountListItem {
  display: flex;
  flex-direction: column;
  padding: 0.625rem 1rem;
  margin-bottom: 0.75rem;
  color: var(--colorTextDefault);
  background-color: white;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--colorBorderDefault);
  transition-property: color, border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.accountListItem:last-child {
  margin-bottom: 0;
}

.accountListItem span {
  display: block;
  font-weight: 500;
}

.accountListItem small {
  color: var(--colorTextMuted);
}
