.overlay {
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 18rem;
  background-color: white;
  overflow-y: auto;
}

.container:focus-visible {
  outline: none;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.bodyOpen {
  overflow: hidden;
}

/* Containers for modal sidevar content */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem;
}

.footer {
  padding: 1.5rem;
}
