.previewBanner {
  display: flex;
  align-items: center;
  height: var(--previewBannerHeight) !important;
  margin-top: 0;
  background-color: var(--colorYellow2);
}

.content {
  display: flex;
  align-items: center;
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorTan11);
}

.icon {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}
