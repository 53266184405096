.wrapperContainer {
  width: 100%;
  padding-bottom: 1.5rem;
  background-color: var(--colorTan1);
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.departmentButton {
  margin-right: 1rem;
  composes: ui14Medium from '../../../../../styles/typeography.css';
  color: var(--colorGray11);
}

.repTabsContainer {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-end;
}

.repTabLink {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 2px;
}

.repTabLink.isActive {
  border-bottom: 1px solid var(--colorBlack);
}

.repTabText {
  color: var(--colorGreenDark2);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.mobileContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-top: 0.25rem; /* This extra 4px of top padding ensures the focus outline on the search field is not obscured underneath the header nav */
}

.mobileSearchWrapper {
  flex-grow: 1;
  width: 100%;
}

.departmentsContainer,
.repTabsContainer,
.hidden {
  display: none;
}

/* Mobile Menu wrapped in Modal */

.mobileMenu {
  flex: 1 0;
  position: relative;
  justify-content: space-between;
}

.mobileMenuButton {
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: center;
  letter-spacing: -0.008em;
  text-transform: capitalize;
  color: var(--colorDarkGray);
  background-color: transparent;
  cursor: pointer;
}

.mobileMenuButtonIcon {
  margin-left: 0.5rem;
}

.mobileMenuAuthOptions {
  display: flex;
  padding: 0 1.5rem 1.5rem 1.5rem;
  background-color: var(--colorTan1);
}

.signupButton {
  flex-grow: 1;
  margin-left: 1rem;
}

.menuTitle {
  composes: ui14SemiBold from '../../../../../styles/typeography.css';
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
}

.menuOption {
  composes: ui14Regular from '../../../../../styles/typeography.css';
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: inherit;
}

.mobileBrowseAll {
  composes: ui14SemiBold from '../../../../../styles/typeography.css';
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.menuSeparator {
  width: 100%;
  border: 0;
  padding-top: 1rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--colorGray3);
}

.menuButtonIcon {
  margin-left: 0.5rem;
}

@media (--viewportTabletMin) {
  .departmentsContainer {
    display: block;
  }
  .repTabsContainer {
    display: flex;
  }
  .mobileContainer {
    display: none;
  }
}

.mobileMenuSidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--colorTan1);
}

.mobileMenuSidebarBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem;
}

.mobileMenuSibebarFooter {
  padding: 1.5rem;
}
