.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.container.isTinted {
  background-color: var(--colorPanelBackgroundGrey);
}

.container.hasDarkBackground {
  background-color: var(--colorTan2);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}
