.orderContainer {
  min-width: 16rem;
  max-width: calc(100vw - 32px);
  padding: 0 16px 20px 16px;
  background-color: var(--colorTan2);
  color: var(--colorTextDefault);
  border-radius: 3px;
}

.orderContainer:last-of-type {
  padding-bottom: 16px;
}

.orderHeader {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: white;
}

.orderLabel {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.orderLabel a {
  color: var(--colorBlack2);
  font-weight: 600;
  line-height: 1.125rem;
}

.orderLabel a:hover {
  color: var(--colorPrimary);
}

.orderCaption {
  color: var(--colorTextMuted);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.orderTotals {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0 12px;
  background-color: white;
}

.orderFooter {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: white;
}

.currencyCode {
  font-weight: 500;
  color: var(--colorTextVeryMuted);
}
