.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.content img {
  width: 100%;
  height: 100%;
}

.fitContain {
  object-fit: contain;
}

.fitCover {
  object-fit: cover;
}
