.navItem {
  position: relative;
  display: flex;
  align-items: center;
  height: 2rem;
  color: inherit;
  cursor: pointer;
  border-radius: var(--elementBorderRadius);
  user-select: none;
  padding: 0.5rem;
}

.navItem + .navItem {
  margin-left: 0.5rem;
}

.navItem > a {
  display: flex;
  align-items: center;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.navItem > a:hover {
  color: inherit;
}

.navItem.isPrimary {
  padding: 0.5rem 1rem;
  color: white;
  background-color: var(--colorButtonDefault);
  transition: background-color 200ms ease;
}

.navItem.isPrimary:hover {
  background-color: var(--colorPrimary);
}

.navItem.disabled {
  color: var(--colorTextVeryMuted);
  cursor: default;
}

.navItem.isActive {
  background-color: rgba(0, 0, 0, 0.04);
}

.badgeCount {
  display: block;
  position: absolute;
  z-index: 1;
  top: -0.25rem;
  right: -0.25rem;
  padding: 0 0.25rem;
  font-size: 0.625rem;
  font-weight: 600;
  background-color: var(--colorRed);
  border-radius: 0.25rem;
  color: white;
}
