/* This is a five column grid common to all new style (early 2023) marketplace pages */
.grid {
  display: grid;
  gap: 2rem;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
}

@media (--viewportTabletMin) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
}

@media (--viewportTabletLandscapeMin) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
}

@media (min-width: 1280px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
