.container {
  display: none;
  align-items: center;
  justify-content: center;
  composes: ui12Medium from '../../../../../styles/typeography.css';
  height: 2rem;
  padding: 0.5rem 0;
  color: var(--colorTan10);
  background-color: var(--colorTan2);
}

.text {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.signupLink {
  color: inherit;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .container {
    display: flex;
  }
}
